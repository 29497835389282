import React from 'react'

import { useTranslation } from 'react-i18next';

import Background from 'src/assets/image/bg-process.png'

import ImageComponent from 'src/component/core/image'

export default function Process() {

    const { t } = useTranslation();

    const steps = [
        {
            id: 0,
            backgroundColor: 'bg-[#0156A5]',
            label: t("Application"),
        },
        {
            id: 1,
            backgroundColor: 'bg-[#0065C1]',
            label: t("Contract Signing"),
        },
        {
            id: 2,
            backgroundColor: 'bg-[#0071D9]',
            label: t("Assessment"),
        },
        {
            id: 3,
            backgroundColor: 'bg-[#007FF3]',
            label: t("Review"),
        },
        {
            id: 4,
            backgroundColor: 'bg-[#41A4FF]',
            label: t("Report"),
        },
    ]

    return (
        <div className='relative w-full z-[1]'>
            <div className='absolute h-full left-0 overflow-hidden top-0 z-[0] w-full'>
                <ImageComponent src={Background} alt='Banner' className='absolute h-full left-0 object-cover top-0 w-full' />
            </div>
            <div className='max-w-[1280px] mx-auto px-6 py-24 relative w-full'>
                <h1 className='font-black mx-auto text-center text-secondary'>{t("Validation, Verification and Certification Process")}</h1>
                <p className='italic max-w-[800px] mt-4 mx-auto text-center'>We conduct audits by prioritizing fairness, transparency, and accuracy principle. We also responsible for independently assessing the operations  of an entity to provide an unbiased report on its compliance with relevant standards and laws.</p>
                <div className='flex flex-col gap-6 h-[280px] items-center mt-12 relative w-full lg:flex-row lg:gap-0'>
                    {steps?.map(item => {
                        return (
                            <div key={item?.id} className={`${item?.backgroundColor} flex gap-3 items-center p-1 rounded-full w-[340px] lg:absolute lg:-rotate-45 lg:top-1/2`} style={{
                                left: `calc(50% - 430px + 128px * ${item?.id})`
                            }}>
                                <div className='bg-white caption1 flex font-semibold h-9 items-center justify-center relative rounded-full text-secondary w-9'>
                                    {item?.id + 1}
                                </div>
                                <p className='caption2 font-semibold text-white'>{item?.label}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
