import axios from 'axios'
import { Link } from 'react-router-dom'
import { render } from '@react-email/components'
import { toast } from 'react-toastify'

import React, { Fragment, useState } from 'react'
import { Envelope, InstagramLogo, LinkedinLogo, WhatsappLogo } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

import Button from 'src/component/core/button'
import InputText from 'src/component/core/input/input-text'
import InputTextArea from 'src/component/core/input/input-textarea'
import Select from 'src/component/core/select'

import Banner from './Banner'
import ContactUsEmail from './ContactUsEmail'

export default function ContactUsPage() {

    const [getLoading, setLoading] = useState(false);

    const [getCompanyName, setCompanyName] = useState('');
    const [getCountry, setCountry] = useState('');
    const [getEmail, setEmail] = useState('');
    const [getFirstName, setFirstName] = useState('');
    const [getMessage, setMessage] = useState('');
    const [getLastName, setLastName] = useState('');
    const [getPhone, setPhone] = useState('');
    const [getService, setService] = useState(null);
    const [getSubject, setSubject] = useState('');

    const { t } = useTranslation();

    const SubmitForm = async (e) => {
        e.preventDefault();

        setLoading(true);

        await axios.post(process.env.REACT_APP_WEB_URL + "/submit-form-complaint", {
            from: getEmail,
            to: process.env.REACT_APP_EMAIL_DESTINATION,
            subject: getSubject,
            message: render(
                <ContactUsEmail
                    companyName={getCompanyName}
                    country={getCountry}
                    email={getEmail}
                    firstName={getFirstName}
                    message={getMessage}
                    lastName={getLastName}
                    phone={getPhone}
                    service={getService?.value}
                />
            )
        }).catch(err => {
            toast.error("Failed to submit form!");
            console.log(err);
        }).finally(() => {
            setCompanyName('');
            setCountry('');
            setEmail('');
            setFirstName('');
            setMessage('');
            setLastName('');
            setPhone('');
            setSubject('');

            toast.success("Your form has been submitted");

            setLoading(false);
        });
    }

    return (
        <Fragment>
            <Banner />
            <div className='max-w-[1280px] mx-auto px-6 py-10 relative w-full lg:py-24'>
                <div className='grid gap-6 relative'>
                    <h2 className='font-black text-secondary'>{t("CONNECT WITH US!")}</h2>
                    <div className='flex flex-col gap-10 relative w-full lg:flex-row'>
                        <form className='grid gap-6 relative w-full' onSubmit={SubmitForm}>
                            <div className='gap-6 grid relative w-full sm:grid-cols-2'>
                                <InputText
                                    label={t("Company Name")}
                                    onChange={e => setCompanyName(e?.target?.value)}
                                    required={true}
                                    value={getCompanyName}
                                />
                                <InputText
                                    label={t("Work Email Address")}
                                    onChange={e => setEmail(e?.target?.value)}
                                    required={true}
                                    type='email'
                                    value={getEmail}
                                />
                            </div>
                            <div className='gap-6 grid relative w-full sm:grid-cols-2'>
                                <InputText
                                    label={t("First Name")}
                                    onChange={e => setFirstName(e?.target?.value)}
                                    required={true}
                                    value={getFirstName}
                                />
                                <InputText
                                    label={t("Last Name")}
                                    onChange={e => setLastName(e?.target?.value)}
                                    required={true}
                                    value={getLastName}
                                />
                            </div>
                            <div className='gap-6 grid relative w-full sm:grid-cols-2'>
                                <InputText
                                    label={t("Country")}
                                    onChange={e => setCountry(e?.target?.value)}
                                    required={true}
                                    value={getCountry}
                                />
                                <InputText
                                    label={t("Phone Number")}
                                    onChange={e => setPhone(e?.target?.value)}
                                    required={true}
                                    type='number'
                                    value={getPhone}
                                />
                            </div>
                            <div className='gap-6 grid relative w-full sm:grid-cols-2'>
                                <InputText
                                    label={t("Subject")}
                                    onChange={e => setSubject(e?.target?.value)}
                                    required={true}
                                    value={getSubject}
                                />
                                <Select
                                    label={t("Services")}
                                />
                            </div>
                            <InputTextArea
                                label={t("Message")}
                                onChange={e => setMessage(e?.target?.value)}
                                required={true}
                                value={getMessage}
                            />
                            <p>{t("How did you hear about us?")} <Link to={'/terms-conditions'} target='_blank' className='font-semibold text-third'>{t("Terms and Conditions")}</Link> {t("and")} <Link to={'/privacy-policy'} target='_blank' className='font-semibold text-third'>{t("Privacy Policy")}</Link></p>
                            <Button type='submit' classNameAdditional='bg-secondary font-semibold py-3 px-24 text-white w-fit' disabled={[getCompanyName, getCountry, getFirstName, getLastName, getEmail, getPhone, getSubject, getMessage].some(item => !item)} isLoading={getLoading} >
                                {t("Send")}
                            </Button>
                        </form>
                        <div className='flex flex-row gap-10 relative w-full lg:flex-col lg:gap-6 lg:max-w-[280px]'>
                            <div className='relative'>
                                <h5 className='font-bold text-secondary'>{t("Address")}:</h5>
                                <Link to={'https://www.google.com/maps/place/Citylofts+Sudirman/@-6.209024,106.8158243,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f40284aeba2d:0x8523f9ff207a98e7!8m2!3d-6.209024!4d106.8183992!16s%2Fg%2F1td1y1pq?entry=ttu'} target='_blank'>
                                    <p className='leading-6'>
                                        Cityloft Sudirman 820. 13, Jl. K.H. Mas Mansyur No.121, RT.13/RW.11, Karet Tengsin, Kecamatan Tanah Abang, Kota Jakarta Pusat,
                                        <br />
                                        Daerah Khusus Ibukota
                                        <br />
                                        Jakarta 10220
                                        <br />
                                        Indonesia
                                    </p>
                                </Link>
                            </div>
                            <div className='flex flex-1 flex-col gap-6 relative whitespace-nowrap'>
                                <div className='relative'>
                                    <h5 className='font-bold text-secondary'>{t("Phone")}:</h5>
                                    <Link to={'https://wa.me/+6285121006661'}>
                                        (+62) 851-2100-6661
                                    </Link>
                                </div>
                                <div className='relative'>
                                    <h5 className='font-bold text-secondary'>Email:</h5>
                                    <Link to={'mailto:info@naturetrust.id'}>info@naturetrust.id</Link>
                                </div>
                            </div>
                            <div className='flex gap-6 items-start mt-6'>
                                <Link to={'https://www.linkedin.com/company/100936494/admin/inbox/'}>
                                    <LinkedinLogo color='#205872' size={40} weight='fill' />
                                </Link>
                                <InstagramLogo color='#205872' size={40} weight='fill' />
                                <Link to={'mailto:info@naturetrust.id'}>
                                    <Envelope color='#205872' size={40} weight='fill' />
                                </Link>
                                <Link to={'https://wa.me/+6285121006661'}>
                                    <WhatsappLogo color='#205872' size={40} weight='fill' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
