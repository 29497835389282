import React from 'react'
import Loading from '../../loading';

/**
 * 
 * @param {{
 * children: any;
 * className: string;
 * classNameAdditional: string;
 * disabled: boolean;
 * isLoading: boolean;
 * onClick: React.MouseEventHandler<HTMLButtonElement>;
 * type: 'button' | 'reset' | 'submit';
 * variant: 'bordered' | 'fill';
 * }} props 
 * @returns 
 */

export default function Button(props) {

    const {
        className = `${props?.variant === 'bordered' ? 'border border-2' : ''} rounded-full`,
        classNameAdditional = 'px-4 py-2',
        children = '',
        disabled = false,
        isLoading = false,
        onClick = undefined,
        type = 'button',
    } = props;

    return (
        <button type={type} className={[className, classNameAdditional]?.filter(Boolean)?.join(' ')} disabled={disabled || isLoading} onClick={onClick}>
            {isLoading ?
                <Loading theme={'light'} />
                :
                children
            }
        </button>
    )
}
