import React from 'react'

/**
 * 
 * @param {{
 * children: any;
 * classNameContainer: string;
 * classNameContainerAdditional: string;
 * classNameInputContainer: string;
 * classNameInputContainerAdditional: string;
 * classNameLabel: string;
 * classNameLabelAdditional: string;
 * classNameLeftSpan: string;
 * classNameLeftSpanAdditional: string;
 * classNameRightSpan: string;
 * classNameRightSpanAdditional: string;
 * label: string;
 * leftSpan: any;
 * required: boolean;
 * rightSpan: any;
 * }} props 
 * @returns 
 */

export default function Input(props) {

    const {
        children,
        classNameContainer = 'grid gap-1 relative',
        classNameContainerAdditional = '',
        classNameInputContainer = 'border-[1px] border-border bg-white flex items-center gap-2 overflow-hidden rounded-lg w-full',
        classNameInputContainerAdditional = '',
        classNameLabel = 'caption2',
        classNameLabelAdditional = '',
        classNameLeftSpan = 'pl-3 py-2 relative',
        classNameLeftSpanAdditional = '',
        classNameRightSpan = 'pr-3 py-2 relative',
        classNameRightSpanAdditional = '',
        label = '',
        leftSpan = null,
        required = false,
        rightSpan = null,
    } = props;

    return (
        <div className={[classNameContainer, classNameContainerAdditional]?.filter(Boolean)?.join(' ')}>
            {label ?
                <p className={[classNameLabel, classNameLabelAdditional]?.filter(Boolean)?.join(' ')}>{label} {required ? <span className='text-danger'>*</span> : null}</p>
                : null
            }
            <div className={[classNameInputContainer, classNameInputContainerAdditional]?.filter(Boolean)?.join(' ')}>
                {leftSpan ?
                    <div className={[classNameLeftSpan, classNameLeftSpanAdditional]?.filter(Boolean)?.join(' ')}>
                        {leftSpan}
                    </div>
                    : null
                }
                {children}
                {rightSpan ?
                    <div className={[classNameRightSpan, classNameRightSpanAdditional]?.filter(Boolean)?.join(' ')}>
                        {rightSpan}
                    </div>
                    : null
                }
            </div>
        </div>
    )
}
