import axios from 'axios';
import React, { useState } from 'react'

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Background from 'src/assets/image/bg-form.png';

import Button from 'src/component/core/button'
import ComplaintEmail from './ComplaintEmail'
import ImageComponent from 'src/component/core/image'
import InputText from 'src/component/core/input/input-text';
import InputTextArea from 'src/component/core/input/input-textarea';
import { render } from '@react-email/components';

export default function ComplaintForm({
    refFormComplaint = null
}) {

    const [getLoading, setLoading] = useState(false);

    const [getEmail, setEmail] = useState('');
    const [getFullName, setFullName] = useState('');
    const [getInstitute, setInstitute] = useState('');
    const [getMessage, setMessage] = useState('');
    const [getPhone, setPhone] = useState('');
    const [getSituation, setSituation] = useState('');
    const [getSubject, setSubject] = useState('');

    const { t } = useTranslation();

    const SubmitForm = async (e) => {
        e.preventDefault();

        setLoading(true);

        await axios.post(process.env.REACT_APP_WEB_URL + "/submit-form-complaint", {
            from: getEmail,
            to: process.env.REACT_APP_EMAIL_DESTINATION,
            subject: getSubject,
            message: render(<ComplaintEmail email={getEmail} fullname={getFullName} institute={getInstitute} message={getMessage} phone={getPhone} situation={getSituation} />)
        }).catch(err => {
            toast.error("Failed to submit form!");
        }).finally(() => {
            setEmail('');
            setFullName('');
            setInstitute('');
            setMessage('');
            setPhone('');
            setSituation('');
            setSubject('');

            toast.success("Your complain has been submitted");

            setLoading(false);
        });
    }

    return (
        <div ref={refFormComplaint} className='flex flex-col gap-12 max-w-[1280px] mx-auto px-6 py-10 relative w-full xl:flex-row xl:py-24'>
            <div className='hidden relative w-full xl:block xl:max-w-[512px]'>
                <ImageComponent src={Background} alt='Background' className='h-full object-cover' width={512} />
            </div>
            <form className='relative w-full' onSubmit={SubmitForm}>
                <div className='relative'>
                    <h1 className='font-bold text-secondary'>{t("Complaint & Appeals Form")}</h1>
                    <div className='bg-secondary h-1 mt-3 rounded-full w-[240px]'></div>
                </div>
                <div className='grid gap-3 mt-12 relative w-full'>
                    <div className='gap-3 grid relative w-full sm:grid-cols-2'>
                        <InputText
                            label={t("Situation")}
                            onChange={e => setSituation(e?.target?.value)}
                            required={true}
                            value={getSituation}
                        />
                        <InputText
                            label={t("Full Name")}
                            onChange={e => setFullName(e?.target?.value)}
                            required={true}
                            value={getFullName}
                        />
                    </div>
                    <InputText
                        label={t("Institute")}
                        onChange={e => setInstitute(e?.target?.value)}
                        required={true}
                        value={getInstitute}
                    />
                    <div className='gap-3 grid relative w-full sm:grid-cols-2'>
                        <InputText
                            label={t("Email")}
                            onChange={e => setEmail(e?.target?.value)}
                            required={true}
                            type='email'
                            value={getEmail}
                        />
                        <InputText
                            label={t("Phone")}
                            onChange={e => setPhone(e?.target?.value)}
                            required={true}
                            type='number'
                            value={getPhone}
                        />
                    </div>
                    <InputText
                        label={t("Subject")}
                        onChange={e => setSubject(e?.target?.value)}
                        required={true}
                        value={getSubject}
                    />
                    <InputTextArea
                        label={t("Message")}
                        onChange={e => setMessage(e?.target?.value)}
                        required={true}
                        rows={5}
                        value={getMessage}
                    />
                </div>
                <Button type='submit' classNameAdditional='bg-secondary font-semibold mt-10 px-12 py-3 text-white w-fit' disabled={[getSituation, getFullName, getInstitute, getEmail, getPhone, getSubject, getMessage].some(item => !item)} isLoading={getLoading} variant='fill'>
                    {t("Submit")}
                </Button>
            </form>
        </div>
    )
}
