import Slider from 'react-slick'

import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';

import { CaretLeft, CaretRight } from '@phosphor-icons/react'

import BackgroundBanner from 'src/assets/image/bg-home-banner.png'
import BackgroundBanner2 from 'src/assets/image/bg-home-banner-2.jpg'
import BackgroundBanner3 from 'src/assets/image/bg-home-banner-3.jpg'
import BackgroundBanner4 from 'src/assets/image/bg-home-banner-4.jpg'
import BackgroundBanner5 from 'src/assets/image/bg-home-banner-5.jpg'
import BackgroundBanner6 from 'src/assets/image/bg-home-banner-6.jpg'
import IconLogoSmallWhite from 'src/assets/image/ic-logo-s-white.svg'

import ImageComponent from 'src/component/core/image'

export default function Banner() {

    const { t } = useTranslation();

    const refContainer = useRef();

    const settingsContainer = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 1000,
    };

    return (
        <div className='relative w-full z-[1]'>
            <Slider ref={refContainer} {...settingsContainer}>
                <div className='relative w-full'>
                    <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                        <ImageComponent src={BackgroundBanner} alt='Banner' className='absolute h-full left-0 object-cover top-0 w-full' />
                        <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                        <ImageComponent src={IconLogoSmallWhite} alt='Logo' className='absolute left-1/2 pb-48 opacity-40 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[1]' height={254} width={254} />
                    </div>
                    <div className='flex gap-12 h-[600px] items-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                        <div className='flex flex-col gap-3 mx-auto px-8 relative w-full lg:px-20'>
                            <h1 className='font-black text-center text-white'>{t("NATURETRUST VERIFIKATOR INDONESIA")}</h1>
                            <div className='bg-white h-1 mx-auto rounded-lg w-full xs:w-[400px]'></div>
                            <h6 className='font-semibold text-center text-white'>&quot;{t("Your trusted partner for certification, validation and verification of Environmental Project")}&quot;</h6>
                        </div>
                    </div>
                </div>
                <div className='relative w-full'>
                    <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                        <ImageComponent src={BackgroundBanner2} alt='Banner 2' className='absolute h-full left-0 object-cover top-0 w-full' />
                        <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                    </div>
                    <div className='flex gap-12 h-[600px] items-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                        <div className='flex flex-col gap-3 mx-auto px-8 relative w-full lg:px-20'>
                            <h1 className='font-black text-center text-white'>{t("CARBON AND BIOMASS CERTIFICATION")}</h1>
                            <div className='bg-white h-1 mx-auto rounded-lg w-full xs:w-[400px]'></div>
                            <h6 className='font-semibold text-center text-white'>{t("We evaluate compliance with biomass and carbon certification requirements")}</h6>
                        </div>
                    </div>
                </div>
                <div className='relative w-full'>
                    <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                        <ImageComponent src={BackgroundBanner3} alt='Banner 3' className='absolute h-full left-0 object-cover top-0 w-full' />
                        <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                    </div>
                    <div className='flex gap-12 h-[600px] items-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                        <div className='flex flex-col gap-3 mx-auto px-8 relative w-full lg:px-20'>
                            <h1 className='font-black text-center text-white'>{t("GHG VALIDATION AND VERIFICATION")}</h1>
                            <div className='bg-white h-1 mx-auto rounded-lg w-full xs:w-[400px]'></div>
                            <h6 className='font-semibold text-center text-white'>{t("We conduct validation and verification of Greenhouse Gases (GHG) emission reports at both organizational and project levels")}</h6>
                        </div>
                    </div>
                </div>
                <div className='relative w-full'>
                    <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                        <ImageComponent src={BackgroundBanner4} alt='Banner 4' className='absolute h-full left-0 object-cover top-0 w-full' />
                        <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                    </div>
                    <div className='flex gap-12 h-[600px] items-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                        <div className='flex flex-col gap-3 mx-auto px-8 relative w-full lg:px-20'>
                            <h1 className='font-black text-center text-white'>{t("VISION")}</h1>
                            <div className='bg-white h-1 mx-auto rounded-lg w-full xs:w-[100px]'></div>
                            <h6 className='font-semibold text-center text-white'>&quot;{t("Our vision is to be the leading one-stop provider of certification, validation, and verification services in the Asia Pacific region")}&quot;</h6>
                        </div>
                    </div>
                </div>
                <div className='relative w-full'>
                    <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                        <ImageComponent src={BackgroundBanner5} alt='Banner 5' className='absolute h-full left-0 object-cover top-0 w-full' />
                        <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                    </div>
                    <div className='flex gap-12 h-[600px] items-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                        <div className='flex flex-col gap-3 mx-auto px-8 relative w-full lg:px-20'>
                            <h1 className='font-black text-center text-white'>{t("MISSION")}</h1>
                            <div className='bg-white h-1 mx-auto rounded-lg w-full xs:w-[100px]'></div>
                            <h6 className='font-semibold text-center text-white'>{t("We provide trusted certification, validation, and verification through impartial assessments, sound practices, and a diverse team of experts, ensuring both speed and quality")}</h6>
                            <h6 className='font-semibold text-center text-white'>{t("Our services enable decision-makers to choose wisely, ensuring products meet their standards")}</h6>
                        </div>
                    </div>
                </div>
                <div className='relative w-full'>
                    <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                        <ImageComponent src={BackgroundBanner6} alt='Banner 6' className='absolute h-full left-0 object-cover top-0 w-full' />
                        <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                    </div>
                    <div className='flex gap-12 h-[600px] items-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                        <div className='flex flex-col gap-3 mx-auto px-8 relative w-full lg:px-20'>
                            <h1 className='font-black text-center text-white'>{t("OUR COMMITMENT")}</h1>
                            <div className='bg-white h-1 mx-auto rounded-lg w-full xs:w-[100px]'></div>
                            <h6 className='font-semibold text-center text-white'>{t("Our objective is to support organizations, projects, manager and communities on their path to environmental sustainability")}</h6>
                            <h6 className='font-semibold text-center text-white'>{t("We ensure precise measurement of their carbon footprints, facilitate meaningful climate transitions, and contribute significantly to the global effort against climate change")}</h6>
                            <h6 className='font-semibold text-center text-white'>{t("Accordingly, we offer not merely a service, but a partnership characterized by expertise, diligence, and an unwavering commitment to a sustainable future")}</h6>
                        </div>
                    </div>
                </div>
            </Slider>
            <CaretLeft color='#FFFFFF' className='absolute cursor-pointer left-2 top-1/2 text-4xl -translate-y-1/2 lg:text-[92px]' onClick={() => refContainer?.current?.slickPrev()} />
            <CaretRight color='#FFFFFF' className='absolute cursor-pointer right-2 top-1/2 text-4xl -translate-y-1/2 lg:text-[92px]' onClick={() => refContainer?.current?.slickNext()} />
        </div>
    )
}
