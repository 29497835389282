import React from 'react'

import { useTranslation } from 'react-i18next'

import Background from 'src/assets/image/bg-complaint.png'
import IconLogoSmallWhite from 'src/assets/image/ic-logo-s-white.svg'

import Button from 'src/component/core/button'
import ImageComponent from 'src/component/core/image'

export default function Complaint({
    formComplaint = null,
    startComplaint = null
}) {

    const { t } = useTranslation();

    return (
        <div className='relative w-full z-[1]'>
            <div className='absolute h-[600px] left-0 overflow-hidden top-0 z-[0] w-full'>
                <ImageComponent src={Background} alt='Banner' className='absolute h-full left-0 object-cover top-0 w-full' />
                <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                <ImageComponent src={IconLogoSmallWhite} alt='Logo' className='absolute left-1/2 opacity-5 top-1/2 -translate-x-1/2 -translate-y-1/2 z-[1]' height={512} width={512} />
            </div>
            <div className='flex flex-col gap-12 h-[600px] items-center justify-center max-w-[820px] mx-auto px-6 relative w-full z-[1]'>
                <div className='flex flex-col gap-3 relative w-full'>
                    <h1 className='font-black text-center text-white'>{t("Unsatisfied with our service and certification result?")}<br />...{t("Let us know")}...</h1>
                    <h6 className='font-semibold italic text-center text-white'>({t("before start, please check Complaint & Appeals Procedure")})</h6>
                </div>
                <Button classNameAdditional='border-white font-semibold px-12 py-4 text-white' variant='bordered' onClick={() => startComplaint(formComplaint.current)}>
                    {t("Start Complaint & Appeals Procedure")}
                </Button>
            </div>
        </div>
    )
}
