import React, { Fragment, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom';

import Banner from './Banner'
import Content from './Content'

export default function CertificationKnowledgePage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const refGGL = useRef(null);
    const refISCC = useRef(null);
    const refSPEI = useRef(null);

    useEffect(() => {
        if (searchParams.has('scrollTo') && refGGL?.current && refISCC?.current && refSPEI?.current) {
            if (searchParams.get('scrollTo') === 'GGL') {
                ScrollToElement(refGGL.current);
            } else if (searchParams.get('scrollTo') === 'ISCC') {
                ScrollToElement(refISCC.current);
            } else if (searchParams.get('scrollTo') === 'SPEI') {
                ScrollToElement(refSPEI.current);
            }

            searchParams.delete('scrollTo');
            setSearchParams(searchParams);
        }
    }, [refGGL, refISCC, refSPEI, searchParams, setSearchParams]);

    const ScrollToElement = (element = null) => {
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <Fragment>
            <Banner />
            <Content refGGL={refGGL} refISCC={refISCC} refSPEI={refSPEI} />
        </Fragment>
    )
}
