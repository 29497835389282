import React, { useEffect, useRef, useState } from 'react'
import { CaretDown, MagnifyingGlass, X } from '@phosphor-icons/react';

import InputText from '../input/input-text';

/**
 * 
 * @param {{
 * classNameContainer: string;
 * classNameContainerAdditional: string;
 * classNameInput: string;
 * classNameInputAdditional: string;
 * classNameInputContainer: string;
 * classNameInputContainerAdditional: string;
 * classNameLabel: string;
 * classNameLabelAdditional: string;
 * label: string;
 * leftSpan: any;
 * multiple: boolean;
 * required: boolean;
 * onChange: React.ChangeEventHandler<HTMLInputElement>;
 * options: Array;
 * placeholder: string;
 * searchable: boolean;
 * type: React.HTMLInputTypeAttribute;
 * value: Object | undefined;
 * rest: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
 * }} props 
 * @returns 
 */

export default function Select(props) {

    const {
        classNameContainer = 'grid gap-1 relative',
        classNameContainerAdditional = '',
        classNameInput = 'flex gap-2 items-center h-10 overflow-auto px-3 w-full',
        classNameInputAdditional = '',
        classNameInputContainer = 'bg-white border-[1px] border-border cursor-pointer flex items-center gap-2 overflow-hidden rounded-lg w-full',
        classNameInputContainerAdditional = '',
        classNameInputSearch = 'bg-white px-3 py-2 rounded-lg w-full',
        classNameInputSearchAdditional = '',
        classNameLabel = 'caption2',
        classNameLabelAdditional = '',
        label = '',
        leftSpan = null,
        multiple = false,
        onChange = undefined,
        options = [],
        placeholder = '',
        required = false,
        searchable = false,
        value = undefined,
    } = props;

    const [getOpenList, setOpenList] = useState(false);

    const [getValueSearch, setValueSearch] = useState("");
    const [getValueSelected, setValueSelected] = useState([]);

    const refContainer = useRef();

    useEffect(() => {
        document.addEventListener('mouseup', event => CloseListOption(event));

        return () => document.removeEventListener('mouseup', CloseListOption);
    }, [getOpenList]);

    const CloseListOption = (e) => {
        if (getOpenList) {
            if (refContainer?.current && !refContainer?.current?.contains(e?.target)) {
                setOpenList(false);
                setValueSearch('');
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    const RemoveAllItem = () => {
        setValueSelected([]);
        onChange && onChange([]);
    }

    // eslint-disable-next-line no-unused-vars
    const RemoveItem = (index) => {
        let selected = getValueSelected ? [...getValueSelected] : [];

        selected?.splice(index, 1);

        if (selected?.length === 0) selected = null;

        setValueSelected(selected);

        if (multiple) {
            onChange && onChange(selected);
        } else {
            onChange && onChange(selected);
        }
    }

    // eslint-disable-next-line no-unused-vars
    const SelectAllItem = () => {
        setOpenList(false);
        setValueSelected(options);
        onChange && onChange(options);
    }

    // eslint-disable-next-line no-unused-vars
    const SelectItem = (item) => {
        let selected = getValueSelected ? [...getValueSelected] : [];

        if (multiple) {
            const check = selected?.findIndex(data => data?.value === item?.value);

            if (check < 0 && item) selected.push(item);
            else selected?.splice(check, 1);

            if (selected?.length === 0) selected = null;

            setValueSelected(selected);
        } else {
            selected = item;

            setValueSelected(selected ? [selected] : []);
            setOpenList(false);
        }

        setValueSearch("");
        onChange && onChange(selected);
    }

    const dataListOption = options?.filter(item => item?.label?.toString()?.toLowerCase()?.includes(getValueSearch?.toLowerCase()));

    return (
        <div className={[classNameContainer, classNameContainerAdditional]?.filter(Boolean)?.join(' ')}>
            {label ?
                <p className={[classNameLabel, classNameLabelAdditional]?.filter(Boolean)?.join(' ')}>{label} {required ? <span className='text-danger'>*</span> : null}</p>
                : null
            }
            <div ref={refContainer} className='relative'>
                <div className={[classNameInputContainer, classNameInputContainerAdditional]?.filter(Boolean)?.join(' ')} onClick={() => setOpenList(prevState => !prevState)}>
                    {leftSpan ?
                        <div className='pl-3 py-2 relative'>
                            {leftSpan}
                        </div>
                        : null
                    }
                    <div className={[classNameInput, classNameInputAdditional]?.filter(Boolean)?.join(' ')}>
                        {value ?
                            multiple ?
                                <div className='bg-border cursor-default flex gap-2 items-center px-2 py-[2px] relative rounded-md' onClick={e => e.stopPropagation()}>
                                    <button type='button'>
                                        <X color='#5f5f5f' size={12} weight='bold' />
                                    </button>
                                    <p className='body2'>{options?.[0]?.label}</p>
                                </div>
                                :
                                <p className='body2'>{options?.[0]?.label}</p>
                            :
                            <p className='caption2 text-gray-400'>{placeholder ? placeholder : label}</p>
                        }
                    </div>
                    <button type='button' className='border-l px-3 py-2'>
                        <CaretDown color='#5f5f5f' size={20} />
                    </button>
                </div>
                {getOpenList ?
                    <ul className='absolute bg-white border border-border rounded-lg shadow-md top-[calc(100%+4px)] w-full z-[1]'>
                        {(searchable && options?.length > 0) ?
                            <div className='border-b p-2 relative'>
                                <InputText
                                    classNameInput={classNameInputSearch}
                                    classNameInputAdditional={classNameInputSearchAdditional}
                                    leftSpan={<MagnifyingGlass color='#5f5f5f' size={24} />}
                                    onChange={e => setValueSearch(e?.target?.value)}
                                    placeholder={placeholder}
                                    required={true}
                                    value={getValueSearch}
                                />
                            </div>
                            : null
                        }
                        {dataListOption?.length > 0 ?
                            <div className='p-2 relative'>
                                {dataListOption.map((item, index) => {
                                    return (
                                        <li key={index} className='cursor-pointer px-3 py-2 rounded-md hover:bg-light' onClick={() => setOpenList(false)}>{item?.label}</li>
                                    )
                                })}
                            </div>
                            :
                            <li className='px-3 py-2 text-center'>Tidak ada data</li>
                        }
                    </ul>
                    : null
                }
            </div>
        </div >
    )
}
