import React, { Fragment, useState } from 'react'

import { useTranslation } from 'react-i18next'

import ImageAprillian from 'src/assets/image/ic-aprillian.png'
import ImageFadly from 'src/assets/image/ic-fadly.png'
import ImageHaryo from 'src/assets/image/ic-haryo.png'
import ImageLayer from 'src/assets/image/ic-layer.png'
import ImageLely from 'src/assets/image/ic-lely.png'
import ImagePutri from 'src/assets/image/ic-putri.png'
import ImageJohn from 'src/assets/image/ic-john.png'
import ImageJessica from 'src/assets/image/ic-jessica.png'
import ImageComponent from 'src/component/core/image'
import { X } from '@phosphor-icons/react'

export default function Content() {

    const { t } = useTranslation();

    const [getSelectedTeam, setSelectedTeam] = useState(null);

    const listTeam = [
        {
            id: 0,
            description: t("Lely is a senior forester specializing in sustainable forest management, silviculture and invasive species| She is a Forest Stewardship Council (FSC) auditor and has been involved in numerous forest management audits in Asia Pacific including Indonesia, Malaysia, Singapore, Thailand, Laos, Australia and Papua New Guinea| Lely has successfully completed the FSC Forest Management and Supply Chain auditor training and  fulfils the training requirements of ISO FSD-STD-20-001| She is also a member of the Quality Panel of the High Conservation Value Network (HCVN) and  is responsible for reviewing HCV (High Conservation Value) assessment reports for oil palm and forestry plantations in Indonesia and Malaysia| Lely holds a bachelor's degree in forestry from Gadjah Mada University and an M|Sc| degree in forestry from Northern Arizona University|"),
            image: ImageLely,
            name: 'Lely Puspitasari',
            summary: t("Forester specializing in sustainable forest management, silviculture and invasive species"),
            title: t("Founder"),
        },
        /* {
            id: 1,
            description: t("Ajie is a highly experienced and dedicated forestry and conservation professional with a strong background in environmental management, climate mitigation and community development| He has a Bachelor of Forestry degree from Gadjah Mada University in Yogyakarta, Indonesia, and a Master of Science degree in Natural Resources from the University of Missouri, Columbia, USA| Ajie has extensive work experience, having held various positions at Rimba Raya Conservation, including Climate Programme Manager, General Manager, and Technical Director| He has also worked with international organisations such as the United Nations Office for Project Services (UNOPS) and HCVN| He is an environmental activist and is committed to conserving Indonesia's rich biodiversity while promoting sustainable development and climate mitigation efforts|"),
            image: ImageHaryo,
            name: 'Haryo Ajie Dewanto',
            summary: t("Highly experienced and dedicated forestry and conservation professional"),
            title: t("AFOLU Advisor"),
        }, */
        {
            id: 2,
            description: t("Putri Yusman is an adept Sustainability and Climate Change expert with robust educational credentials from prestigious institutions, including a Master of Professional Accounting from RMIT University and a Bachelor of Commerce from the University of Melbourne, Putri brings a well-rounded perspective to her GHG advisory roles| Her extensive experience spans across various significant positions in HSBC Commercial Banking, where she developed her expertise in credit and risk analysis, relationship management, and business development over nearly six years| In her recent role as General Manager at TruClimate, Putri spearheaded initiatives aimed at enhancing greenhouse gas inventories and environmental sustainability reporting| Her bilingual proficiency in Indonesian and English, combined with her in-depth knowledge of trade sales and sustainable industry practices, uniquely positions her to advise on integrating sustainable practices into corporate strategies effectively|"),
            image: ImagePutri,
            name: 'Putri Lestari Yusman',
            summary: t("In-depth knowledge of trade sales and sustainable industry practices"),
            title: t("General Manager"),
        },
        {
            id: 3,
            description: t("Pasha earned his bachelor's degree in Forestry from Gadjah Mada University and is actively engaged in management and auditing activities within production and community forests, adhering to the FSC and SVLK standards| He is accredited as a Validator and Verifier for Environmental Services (NEK) by the National Professional Certification Body (BNSP)| To further enhance his expertise as a validator and verifier for Greenhouse Gas (GHG), Pasha has completed training in several ISO standards, including ISO/IEC 17029:2019, ISO 14065:2020, ISO 14064-1:2018, ISO 14064-3:2019, and ISO 14066:2023| Additionally, to strengthen his capabilities as a certification product auditor, Pasha has also been trained in ISO/IEC 17065:2012|"),
            image: ImageAprillian,
            name: 'Aprillian Iqbal Pasha',
            summary: t("Accredited as a Validator and Verifier for Environmental Services  by BNSP"),
            title: t("Auditor"),
        },
        {
            id: 4,
            description: t("Fadly is a graduate of the University of Indonesia with a degree in Biological Sciences| He possesses extensive expertise in ecology, conservation, and environmental science, contributing significantly to biodiversity sampling initiatives, including mangrove studies, across various Indonesian regions| Additionally, Fadly is skilled in preparing sustainability documentation| He is certified as a Validator and Verifier for Environmental Services (NEK) by the National Professional Certification Body (BNSP)| Fadly has also completed training in several ISO standards, including ISO/IEC 17029:2019, ISO 14065:2020, ISO 14066:2023, and ISO/IEC 17065:2012, enhancing his qualifications in environmental management and auditing|"),
            image: ImageFadly,
            name: 'Fadly Muhammad',
            summary: t("Extensive expertise in ecology, conservation, and environmental science"),
            title: t("Certification Officer"),
        },
        {
            id: 5,
            description: t("John are Experienced Finance Manager with a proven track record in managing financial reports and strategies for startups| Skilled in financial analysis, budgeting, forecasting, and reporting| Strong analytical skills combined with strategic financial planning expertise to drive business growth and profitability| John also experienced in Managed financial reporting and analysis, including monthly, quarterly, and annual reports| Developed and monitored budgets, forecasts, and financial models to support decision-making| Conducted variance analysis and identified key performance indicators for performance evaluation| Collaborated with cross-functional teams to optimize financial processes and improve profitability|"),
            image: ImageJohn,
            name: 'John Sunkarim',
            summary: t("Expert in managing financial reports and strategies for startups"),
            title: t("Sales Manager"),
        },
        {
            id: 6,
            description: t("Jessica are Experienced Finance Manager with a strong background in managing financial reports and strategies for startups| Skilled in budgeting, forecasting, financial analysis, and reporting| Proven ability to streamline financial processes and drive business growth through sound financial management practices| Jessica also experienced in Developed and implemented financial policies and procedures, ensuring compliance and efficiency| Managed financial reporting and analysis, providing insights to support decision-making processes| Prepared budgets, forecasts, and variance analyses, optimizing resource allocation and cost control| Collaborated with cross-functional teams to drive financial strategies aligned with business goals|"),
            image: ImageJessica,
            name: 'Jessica Barata',
            summary: t("Expert in Finance Accounting and Tax"),
            title: t("Finance Manager"),
        },
    ]

    return (
        <Fragment>
            <div className='flex flex-col gap-12 justify-center max-w-[1280px] mx-auto px-6 py-24 relative flex-wrap w-full md:flex-row'>
                {listTeam.map(item => {
                    return (
                        <div key={item?.id} className='flex flex-col items-center w-full lg:w-[calc(30%-24px)] md:w-[calc(50%-24px)]'>
                            <button type='button' className='aspect-[12/10.5] group overflow-hidden relative rounded-3xl' onClick={() => setSelectedTeam(item)}>
                                <div className='absolute duration-300 h-full hidden items-center justify-center left-0 opacity-0 top-0 transition-opacity w-full group-hover:opacity-100 sm:flex'>
                                    <ImageComponent src={ImageLayer} alt={item?.name} className='absolute w-full left-0 object-cover top-0' height={400} width={480} />
                                    <p className='max-w-[80%] mt-3 text-center text-white z-[1]'>{item?.summary}</p>
                                </div>
                                <ImageComponent src={item?.image} alt={item?.name} className='w-full object-cover' height={400} width={480} />
                            </button>
                            <div className='mt-6 relative text-center'>
                                <h6 className='font-bold'>{item?.name}</h6>
                                <p className='font-semibold sub2 text-primary'>{item?.title}</p>
                            </div>
                            <p className='max-w-[80%] mt-3 text-center sm:hidden'>{item?.summary}</p>
                        </div>
                    )
                })}
            </div>
            {getSelectedTeam ?
                <div className='flex fixed h-full items-center justify-center left-0 p-6 top-0 w-full z-10'>
                    <button type='button' className='absolute bg-dark/40 h-full left-0 top-0 w-full z-0' onClick={() => setSelectedTeam(null)} ></button>
                    <div className='backdrop-blur-sm bg-white/80 border border-secondary/80 max-w-[1024px] p-6 rounded-2xl z-[1]'>
                        <X className='absolute cursor-pointer right-4 top-4' size={24} weight='regular' onClick={() => setSelectedTeam(null)} />
                        <h6 className='font-black text-secondary'>OUR TEAM</h6>
                        <div className='flex gap-6 mt-6 relative'>
                            <div className='relative'>
                                <div className='aspect-[12/10.5] overflow-hidden relative'>
                                    <ImageComponent src={getSelectedTeam?.image} alt={getSelectedTeam?.name} className='max-w-[240px] object-cover' height={200} width={240} />
                                </div>
                                <div className='mt-6 relative text-center'>
                                    <h6 className='font-bold'>{getSelectedTeam?.name}</h6>
                                    <p className='font-semibold sub2 text-primary'>{getSelectedTeam?.title}</p>
                                </div>
                                <p className='max-w-[80%] mt-3 text-center sm:hidden'>{getSelectedTeam?.description}</p>
                            </div>
                            <div className='relative'>
                                <p>{getSelectedTeam?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </Fragment>
    )
}
