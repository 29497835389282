import React, { Fragment } from 'react'

import Banner from './Banner'
import Content from './Content'

export default function OurTeamPage() {
    return (
        <Fragment>
            <Banner />
            <Content />
        </Fragment>
    )
}
