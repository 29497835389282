import React from 'react'

import { useTranslation } from 'react-i18next';

import BackgroundBanner from 'src/assets/image/bg-home-banner.png'

import ImageComponent from 'src/component/core/image'

export default function Banner() {

    const { t } = useTranslation();

    return (
        <div className='relative w-full z-[1]'>
            <div className='absolute h-[300px] left-0 overflow-hidden top-0 z-[0] w-full'>
                <ImageComponent src={BackgroundBanner} alt='Banner' className='absolute h-full left-0 object-cover top-0 w-full' />
                <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
            </div>
            <div className='flex gap-12 h-[300px] items-center justify-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                <div className='flex flex-col gap-3 max-w-[600px] relative w-full'>
                    <h1 className='font-black text-center text-white'>{t("Contact Us")}</h1>
                    <h6 className='italic text-center text-white'>{t("Your trusted partner for certification, validation and verification of Environmental Project")}</h6>
                </div>
            </div>
        </div>
    )
}
