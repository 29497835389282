import React from 'react'

/**
 * 
 * @param {{
 * size: number;
 * theme: 'normal' | 'light';
 * }} props 
 * @returns 
 */

export default function Loading(props) {

    const {
        size = 24,
        theme = 'normal',
    } = props;

    return (
        <div className='flex items-center justify-center w-full'>
            <div className={`spinner`} style={{ height: size, width: size }}>
                <div style={{ borderColor: theme?.toLowerCase() === 'light' ? 'white transparent transparent transparent' : '', height: size, width: size }}></div>
                <div style={{ borderColor: theme?.toLowerCase() === 'light' ? 'white transparent transparent transparent' : '', height: size, width: size }}></div>
                <div style={{ borderColor: theme?.toLowerCase() === 'light' ? 'white transparent transparent transparent' : '', height: size, width: size }}></div>
                <div style={{ borderColor: theme?.toLowerCase() === 'light' ? 'white transparent transparent transparent' : '', height: size, width: size }}></div>
            </div>
        </div>
    )
}
