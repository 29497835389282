import { CodesandboxLogo, Graph, Leaf, TreeStructure } from '@phosphor-icons/react';
import React from 'react'

import { useTranslation } from 'react-i18next';

import Background from 'src/assets/image/bg-our-goal.png'

import ImageComponent from 'src/component/core/image';

export default function OurValues() {

    const { t } = useTranslation();

    return (
        <div className='py-10 relative w-full z-[1] lg:py-24'>
            <div className='absolute h-full left-0 overflow-hidden top-0 z-[0] w-full'>
                <div className='absolute bg-black/60 h-full left-0 top-0 w-full z-[1]'></div>
                <ImageComponent src={Background} alt='Banner' className='absolute h-full left-0 object-cover top-0 w-full' />
            </div>
            <div className='flex flex-col justify-center max-w-[1280px] mx-auto px-6 relative w-full z-[1]'>
                <h1 className='font-black text-center text-white'>{t("OUR VALUES")}</h1>
                <div className='bg-white h-1 mt-3 mx-auto rounded-lg w-[240px]'></div>
                <div className='grid gap-10 mt-20 sm:grid-cols-2 lg:grid-cols-4'>
                    <div className='relative'>
                        <div className='flex flex-col gap-4 items-center'>
                            <div className='border-4 border-white flex h-24 items-center justify-center rounded-full w-24'>
                                <Graph color='#FFFFFF' size={64} />
                            </div>
                            <h5 className='font-bold h-[56px] text-center text-white'>{t("Expertise and Credibility")}</h5>
                        </div>
                        <p className='mt-6 text-center text-white'>{t("Our expert team, skilled in carbon, forestry, and supply chain auditing, offers precise and reliable insights into sustainability practices")}</p>
                    </div>
                    <div className='relative'>
                        <div className='flex flex-col gap-4 items-center'>
                            <div className='border-4 border-white flex h-24 items-center justify-center rounded-full w-24'>
                                <CodesandboxLogo color='#FFFFFF' size={64} />
                            </div>
                            <h5 className='font-bold h-[56px] text-center text-white'>{t("Customized Solutions")}</h5>
                        </div>
                        <p className='mt-6 text-center text-white'>{t("Recognizing the distinctiveness of each client and project, we offer a diverse array of standards and schemes tailored to meet the specific needs and objectives of each client")}</p>
                    </div>
                    <div className='relative'>
                        <div className='flex flex-col gap-4 items-center'>
                            <div className='border-4 border-white flex h-24 items-center justify-center rounded-full w-24'>
                                <TreeStructure color='#FFFFFF' size={64} />
                            </div>
                            <h5 className='font-bold h-[56px] text-center text-white'>{t("Compliance and Risk Management")}</h5>
                        </div>
                        <p className='mt-6 text-center text-white'>{t("We support our clients in complying with all applicable regulations and standards, thus mitigating the risk of non-compliance and enhancing their reputation in the marketplace")}</p>
                    </div>
                    <div className='relative'>
                        <div className='flex flex-col gap-4 items-center'>
                            <div className='border-4 border-white flex h-24 items-center justify-center rounded-full w-24'>
                                <Leaf color='#FFFFFF' size={64} />
                            </div>
                            <h5 className='font-bold h-[56px] text-center text-white'>{t("Sustainability Enhancement")}</h5>
                        </div>
                        <p className='mt-6 text-center text-white'>{t("We help clients develop sustainable practices that reduce carbon footprints and enhance their corporate image, providing a competitive edge")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
