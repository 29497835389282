import React from 'react'

import { Html } from '@react-email/components'
import { useTranslation } from 'react-i18next';

import ImageComponent from 'src/component/core/image';

import LogoLargeColor from 'src/assets/image/ic-logo-l-color.svg'

/**
 * 
 * @param {{
 * email: string;
 * fullname: string;
 * institute: string;
 * message: string;
 * phone: number;
 * situation: string;
 * }} props 
 * @returns 
 */

export default function ComplaintEmail({
    email = '',
    fullname = '',
    institute = '',
    message = '',
    phone = '',
    situation = '',
}) {

    const { i18n } = useTranslation();

    return (
        <Html lang={i18n.language}>
            <div style={{ padding: 40 }}>
                <div style={{ backgroundColor: '#2058720F', borderRadius: 16, margin: 'auto', maxWidth: 600, padding: '40px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <ImageComponent src={`${process.env.REACT_APP_WEB_URL}/ic-logo-large.png`} alt='Logo' height={64} width={232} />
                    </div>
                    <h1 style={{ color: '#659864', fontSize: 40, fontWeight: 'bold', marginTop: 20, textAlign: 'center' }}>Complaint & Appeals</h1>
                    <table>
                        <tbody>
                            <tr>
                                <td width={200} style={{ padding: '8px 24px', verticalAlign: 'top' }}>Situation</td>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>: {situation}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>Full Name</td>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>: {fullname}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>Institute</td>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>: {institute}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>Email</td>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>: {email}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>Phone</td>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>: {phone}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>Message</td>
                                <td style={{ padding: '8px 24px', verticalAlign: 'top' }}>: {message}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Html>
    )
}
