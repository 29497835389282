import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from './pages/home/page';
import Layout from './pages/Layout';
import OurTeam from './pages/our-team/page';
import ContactUs from './pages/contact-us/page';
import CertificationKnowledge from './pages/certification-knowledge/page';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="certification-knowledge" element={<CertificationKnowledge />} />
                    <Route path="contact-us" element={<ContactUs />} />
                    <Route path="our-team" element={<OurTeam />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
