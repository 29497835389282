// Import Library
import React from 'react';

/**
 * 
 * @param {{
 * alt: string;
 * className: string;
 * height: number | `${number}`;
 * quality: number;
 * src: string | StaticImport;
 * width: number | `${number}`;
 * }} props 
 * @returns 
 */

export default function ImageComponent(props) {

    const {
        alt = '',
        className = '',
        height = undefined,
        src = null,
        width = undefined,
        ...rest
    } = props;

    return (
        <img
            alt={alt}
            className={className}
            height={height}
            src={src}
            width={width}
            {...rest}
        />
    )
}
