import React from 'react'

import Input from '.'

/**
 * 
 * @param {{
 * classNameContainer: string;
 * classNameContainerAdditional: string;
 * classNameInput: string;
 * classNameInputAdditional: string;
 * classNameInputContainer: string;
 * classNameInputContainerAdditional: string;
 * classNameLabel: string;
 * classNameLabelAdditional: string;
 * cols: number;
 * label: string;
 * leftSpan: any;
 * onChange: React.ChangeEventHandler<HTMLInputElement>;
 * placeholder: string;
 * required: boolean;
 * rightSpan: any;
 * rows: number;
 * value: string | undefined;
 * rest: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
 * }} props 
 * @returns 
 */

export default function InputTextArea(props) {

    const {
        classNameInput = 'flex-1 resize-none px-3 py-2 w-full',
        classNameInputAdditional = '',
        cols = 30,
        label = '',
        onChange = undefined,
        placeholder = '',
        rows = 10,
        value = undefined,
        rest
    } = props;

    return (
        <Input {...props}>
            <textarea {...rest} cols={cols} rows={rows} className={[classNameInput, classNameInputAdditional]?.filter(Boolean)?.join(' ')} placeholder={placeholder ? placeholder : label} value={value} onChange={onChange}></textarea>
        </Input>
    )
}
