import React, { Fragment, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import AboutUs from './AboutUs'
import Banner from './Banner'
import Complaint from './Complaint'
import ComplaintForm from './ComplaintForm'
import OurValues from './OurValues'
import Process from './Process'

export default function HomePage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const formComplaint = useRef(null);

    useEffect(() => {
        if (searchParams.has('scrollTo') && formComplaint?.current) {
            if (searchParams.get('scrollTo') === 'Form Complaint') {
                ScrollToElement(formComplaint.current);
            }

            searchParams.delete('scrollTo');
            setSearchParams(searchParams);
        }
    }, [formComplaint, searchParams, setSearchParams]);

    const ScrollToElement = (element = formComplaint.current) => {
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <Fragment>
            <Banner />
            <AboutUs />
            <OurValues />
            <Process />
            <Complaint formComplaint={formComplaint} startComplaint={ScrollToElement} />
            <ComplaintForm refFormComplaint={formComplaint} />
        </Fragment>
    )
}
