import React, { useState } from 'react'

import Input from '.'
import { Eye, EyeSlash } from '@phosphor-icons/react';

/**
 * 
 * @param {{
 * classNameContainer: string;
 * classNameContainerAdditional: string;
 * classNameInput: string;
 * classNameInputAdditional: string;
 * classNameInputContainer: string;
 * classNameInputContainerAdditional: string;
 * classNameLabel: string;
 * classNameLabelAdditional: string;
 * label: string;
 * leftSpan: any;
 * onChange: React.ChangeEventHandler<HTMLInputElement>;
 * placeholder: string;
 * required: boolean;
 * rightSpan: any;
 * type: React.HTMLInputTypeAttribute;
 * value: string | undefined;
 * rest: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
 * }} props 
 * @returns 
 */

export default function InputText(props) {

    const {
        classNameInput = 'flex-1 px-3 py-2 w-full',
        classNameInputAdditional = '',
        label = '',
        onChange = undefined,
        placeholder = '',
        type = 'text',
        value = undefined,
        rest
    } = props;

    const [getShowPassword, setShowPassword] = useState(false);

    return (
        <Input {...props}>
            <input {...rest} type={getShowPassword ? 'text' : type} className={[classNameInput, classNameInputAdditional]?.filter(Boolean)?.join(' ')} placeholder={placeholder ? placeholder : label} value={value} onChange={onChange} />
            {type?.toLowerCase() === 'password' ?
                <button type='button' className='flex h-10 items-center justify-center relative w-10' onClick={() => setShowPassword(prevState => !prevState)}>
                    {getShowPassword ?
                        <Eye size={24} />
                        :
                        <EyeSlash size={24} />
                    }
                </button>
                : null
            }
        </Input>
    )
}
