import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import BackgroundGGL from 'src/assets/image/bg-ggl.svg'
import BackgroundISCC from 'src/assets/image/bg-iscc.svg'
import BackgroundSPEI from 'src/assets/image/bg-spei.svg'
import IconISCC from 'src/assets/image/ic-logo-iscc-color.svg'
import IconSPEI from 'src/assets/image/ic-logo-spei-color.svg'
import IconGGL from 'src/assets/image/ic-logo-ggl-color.svg'

import ImageComponent from 'src/component/core/image'

export default function Content({
    refGGL = null,
    refISCC = null,
    refSPEI = null,
}) {

    const { t } = useTranslation();

    return (
        <Fragment>
            <div className='bg-white relative w-full z-[1]'>
                <div className='absolute h-full hidden left-0 overflow-hidden top-0 z-[0] w-full lg:block'>
                    <ImageComponent src={BackgroundSPEI} alt='SPEI' className='absolute h-full left-0 object-cover top-0 w-1/2' />
                    <div className='absolute bg-white h-full right-0 top-0 w-1/2 z-[1]'></div>
                </div>
                <div ref={refSPEI} className='flex flex-col max-w-[1280px] mx-auto px-6 relative w-full z-[1] lg:flex-row'>
                    <div className='bg-gradient-to-l from-white via-white/30 to-transparent relative w-[546px]'></div>
                    <div className='bg-white py-10 relative w-full lg:px-6 lg:py-24'>
                        <h3 className='font-bold'>{t("GHG Validation and Verification")}</h3>
                        <div className='flex flex-col gap-6 mt-3 relative md:flex-row'>
                            <ImageComponent src={IconSPEI} alt='SPEI' height={150} className='object-contain' />
                            <div className='relative'>
                                <p className='font-bold'>{t("Indonesian GHG Emission Reduction Certification (SPEI)")}</p>
                                <p className='caption2 mt-1 text-justify'>{t("Indonesian GHG Emission Reduction Certification (SPEI) is a mandatory scheme or standard from the Indonesian government regulated through the Minister of Environment and Forestry Decree No| 1131 of 2023 concerning the Indonesian Greenhouse Gas Emission Reduction Certification Scheme which is used as a guideline in the implementation of greenhouse gas emission reductions in Indonesia|")}</p>
                            </div>
                        </div>
                        <p className='caption2 mt-3 text-justify'>
                            {t("Validation is a systematic and documented process by a party not involved in the activity to ensure that the design of the activity implementation fulfils the specified requirements| This validation process is a process carried out by a validation or verification body in assessing the reasonableness of the assumptions reported in the Draft Mitigation Action Document (DRAM)|")}
                            <br /><br />
                            {t("Verification is an activity to ensure the accuracy and quality assurance of action data and Resources submitted by the person in charge of the action into the SRN PPI| This verification process is a process carried out by a validation or verification body in assessing the accuracy of the data stated in the Mitigation Action Achievement Report (LCAM)|")}
                        </p>
                    </div>
                </div>
            </div>
            <div ref={refISCC} className='bg-[#E0EAE0] relative w-full z-[1]'>
                <div className='absolute h-full hidden left-0 overflow-hidden top-0 z-[0] w-full lg:block'>
                    <ImageComponent src={BackgroundISCC} alt='ISCC' className='absolute h-full left-0 object-cover top-0 w-full' />
                    <div className='absolute bg-[#E0EAE0] h-full left-0 top-0 w-1/2 z-[1]'></div>
                </div>
                <div className='flex flex-col max-w-[1280px] mx-auto px-6 relative w-full z-[1] lg:flex-row'>
                    <div className='bg-[#E0EAE0] py-10 relative w-full lg:px-6 lg:py-24'>
                        <h3 className='font-bold'>{t("Carbon and Biomass Certification")}</h3>
                        <div className='flex flex-col gap-6 mt-3 relative md:flex-row'>
                            <ImageComponent src={IconISCC} alt='ISCC' height={150} className='object-contain' />
                            <div className='relative'>
                                <p className='font-bold'>{t("International Sustainability & Carbon Certification (ISCC)")}</p>
                                <p className='caption2 mt-1 text-justify'>{t("International Sustainability & Carbon Certification (ISCC) is an international certification system that covers all types of biological-based raw materials and renewable energy serving the energy, food, feed and chemical sectors| ISCC aims to build a biomass and bioenergy certification system that is transparent, practical and internationally oriented|")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='bg-gradient-to-r from-[#E0EAE0] via-[#E0EAE0]/30 to-transparent relative w-[546px]'></div>
                </div>
            </div>
            <div ref={refGGL} className='bg-white relative w-full z-[1]'>
                <div className='absolute h-full hidden left-0 overflow-hidden top-0 z-[0] w-full lg:block'>
                    <ImageComponent src={BackgroundGGL} alt='GGL' className='absolute h-full left-0 object-cover top-0 w-full' />
                    <div className='absolute bg-white h-full right-0 top-0 w-1/2 z-[1]'></div>
                </div>
                <div className='flex flex-col max-w-[1280px] mx-auto px-6 relative w-full z-[1] lg:flex-row'>
                    <div className='bg-gradient-to-l from-white via-white/30 to-transparent relative w-[546px]'></div>
                    <div className='bg-white py-10 relative w-full lg:px-6 lg:py-24'>
                        <h3 className='font-bold'>{t("Carbon and Biomass Certification")}</h3>
                        <div className='flex flex-col gap-6 mt-3 relative md:flex-row'>
                            <ImageComponent src={IconGGL} alt='GGL' height={150} className='object-contain' />
                            <div className='relative'>
                                <p className='font-bold'>{t("Green Gold Label (GGL)")}</p>
                                <p className='caption2 mt-1 text-justify'>{t("GGL is a certification scheme for sustainable biomass| It provides standards for certain parts of the supply chain, as well as tracking & tracing standards| GGL complies with Dutch regulatory requirements for the use of biomass in energy production|")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
