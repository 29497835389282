import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { CaretDown, Envelope, List } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

import LogoLargeColor from 'src/assets/image/ic-logo-l-color.svg'
import ImageComponent from './core/image'

export default function Navbar() {

    const [getOpenLanguage, setOpenLanguage] = useState(false);
    const [getPathname, setPathname] = useState('');

    const [getShowMenu, setShowMenu] = useState('');

    const { t, i18n } = useTranslation();

    const listMenu = [
        {
            id: 0,
            href: '/',
            title: t("Home"),
        },
        {
            id: 1,
            href: '/our-team',
            title: t("Our Team"),
        },
        /*{
            id: 2,
            href: '/certification-knowledge',
            title: t("Certification Knowledge"),
        },*/
        {
            id: 3,
            href: '/contact-us',
            title: t("Contact Us"),
        },
    ]

    useEffect(() => {
        window.onresize = () => CloseMenu();

        setPathname(window.location.pathname);
    }, []);

    useEffect(() => {
        CloseMenu();
    }, [getPathname]);

    const CloseMenu = () => {
        setShowMenu(false);
    }

    const ChangeLanguage = (language) => {
        i18n.changeLanguage(language);

        setOpenLanguage(false);
    }

    return (
        <nav className='bg-white fixed overflow-visible shadow-md top-0 w-full z-[2] lg:relative lg:shadow-none'>
            <div className='border-b relative w-full'>
                <div className='flex gap-3 items-center justify-end max-w-[1280px] mx-auto px-6 py-2 w-full'>
                    <Link to={'mailto:info@naturetrust.id'} className='flex gap-2 items-center'>
                        <Envelope size={16} />
                        <p className='caption2'>info@naturetrust.id</p>
                    </Link>
                    <div className='relative'>
                        <button type='button' className='flex gap-2 items-center' onClick={() => setOpenLanguage(prevState => !prevState)}>
                            {i18n.language}
                            <CaretDown size={16} weight='regular' />
                        </button>
                        {getOpenLanguage ?
                            <div className='absolute bg-white border rounded-md right-0 top-full z-[1]'>
                                <button type='button' className='caption2 px-10 py-2 relative w-full hover:bg-border' onClick={() => ChangeLanguage("en")}>en</button>
                                <hr />
                                <button type='button' className='caption2 px-10 py-2 relative w-full hover:bg-border' onClick={() => ChangeLanguage("id")}>id</button>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div className='flex gap-10 items-center justify-between max-w-[1280px] mx-auto overflow-visible px-6 py-1 relative w-full'>
                <Link to={'/'} className='w-fit'>
                    <ImageComponent src={LogoLargeColor} alt='Logo' className='object-contain w-[136px] lg:w-[232px]' />
                </Link>
                <div className='hidden items-center relative lg:flex'>
                    {listMenu.map(item => {
                        return (
                            <Link key={item?.id} to={item?.href} className={`font-semibold group px-6 py-7 relative ${item?.href === getPathname ? 'text-primary' : ''}`} onClick={() => setPathname(item?.href)}>
                                {item?.title}
                                <div className={`absolute bg-primary bottom-0 duration-300 h-[2px] left-0 transition-all ${item?.href === getPathname ? 'w-full' : 'w-0 group-hover:w-full'}`}></div>
                            </Link>
                        )
                    })}
                </div>
                <button type='button' className='relative lg:hidden' onClick={() => setShowMenu(prevState => !prevState)}>
                    <List color='#659864' size={28} weight='regular' />
                </button>
                {getShowMenu ?
                    <div className='absolute bg-white flex flex-col left-0 py-4 shadow-xl top-full w-full z-[2]'>
                        {listMenu.map(item => {
                            return (
                                <Link key={item?.id} to={item?.href} className={`font-semibold group px-8 py-4 relative ${item?.href === getPathname ? 'bg-primary/20 text-primary' : ''}`} onClick={() => setPathname(item?.href)}>
                                    {item?.title}
                                </Link>
                            )
                        })}
                    </div>
                    : null
                }
            </div>
        </nav>
    )
}
