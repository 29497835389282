import React from 'react'
import { Link } from 'react-router-dom'

import { Copyright, Envelope, InstagramLogo, LinkedinLogo, WhatsappLogo } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

import IconLogoLargeWhite from 'src/assets/image/ic-logo-l-white.svg'

import ImageComponent from './core/image'

export default function Footer() {

    const { t } = useTranslation();

    return (
        <div className='bg-secondary relative w-full'>
            <div className='flex flex-col gap-10 max-w-[1280px] mx-auto px-6 py-10 relative w-full lg:flex-row lg:py-24'>
                <div className='flex flex-col flex-1 items-center sm:items-end lg:items-start'>
                    <ImageComponent src={IconLogoLargeWhite} alt='Logo' height={64} width={232} className='object-contain' />
                    <div className='flex gap-6 items-center justify-start mt-6 px-4 w-fit lg:px-0'>
                        <Link to={'https://www.linkedin.com/company/100936494/admin/inbox/'}>
                            <LinkedinLogo color='#FFFFFF' size={32} weight='fill' />
                        </Link>
                        <InstagramLogo color='#FFFFFF' size={32} weight='fill' />
                        <Link to={'mailto:info@naturetrust.id'}>
                            <Envelope color='#FFFFFF' size={32} weight='fill' />
                        </Link>
                        <Link to={'https://wa.me/+6285121006661'}>
                            <WhatsappLogo color='#FFFFFF' size={32} weight='fill' />
                        </Link>
                    </div>
                </div>
                <div className='flex flex-1 flex-col gap-3 relative'>
                    <div className='flex flex-col gap-1 relative'>
                        <h6 className='font-bold text-white'>{t("General inquiries")}</h6>
                        <Link to={'mailto:info@naturetrust.id'}>
                            <p className='text-white'>info@naturetrust.id</p>
                        </Link>
                    </div>
                    <div className='flex flex-col gap-1 relative'>
                        <h6 className='font-bold text-white'>{t("Phone")}</h6>
                        <p className='text-white'>+62 851-2100-6661</p>
                    </div>
                    <div className='flex flex-col gap-1 relative'>
                        <h6 className='font-bold text-white'>{t("Headquarter")}</h6>
                        <Link to={'https://www.google.com/maps/place/Citylofts+Sudirman/@-6.209024,106.8158243,17z/data=!3m1!4b1!4m6!3m5!1s0x2e69f40284aeba2d:0x8523f9ff207a98e7!8m2!3d-6.209024!4d106.8183992!16s%2Fg%2F1td1y1pq?entry=ttu'} target='_blank'>
                            <p className='text-white'>
                                Cityloft Sudirman 820. 13, Jl. K.H. Mas Mansyur No.121, RT.13/RW.11, Karet Tengsin, Kecamatan Tanah Abang, Kota Jakarta Pusat,
                                <br />
                                Daerah Khusus Ibukota
                                <br />
                                Jakarta 10220
                                <br />
                                Indonesia
                            </p>
                        </Link>
                    </div>
                </div>
                {/*<div className='flex-1'>
                    <h6 className='font-bold text-white'>{t("Certification Knowledge")}</h6>
                    <div className='flex flex-col gap-3 mt-6 relative'>
                        <Link to={'/certification-knowledge?scrollTo=ISCC'}>
                            <p className='text-white'>ISCC</p>
                        </Link>
                        <Link to={'/certification-knowledge?scrollTo=SPEI'}>
                            <p className='text-white'>SPEI</p>
                        </Link>
                        <Link to={'/certification-knowledge?scrollTo=GGL'}>
                            <p className='text-white'>GGL</p>
                        </Link>
                    </div>
                </div>*/}
                <div className='flex-1'>
                    <h6 className='font-bold text-white'>{t("Quick Links")}</h6>
                    <div className='flex flex-col gap-3 mt-6 relative'>
                        <p className='text-white'>{t("Publication")}</p>
                        <p className='text-white'>{t("Download Centre")}</p>
                        <Link to={'/?scrollTo=Form Complaint'}>
                            <p className='text-white'>{t("Complaint & Appeals")}</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='border-t-[2px] border-t-white flex gap-1 justify-center max-w-[1280px] mx-auto px-6 py-3 relative w-full'>
                <Copyright color='#FFFFFF' size={20} />
                <p className='body1 text-white'>2024 PT Naturetrust Verifikator Indonesia</p>
            </div>
        </div>
    )
}
