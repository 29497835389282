import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Footer from 'src/component/Footer'
import Navbar from 'src/component/Navbar'

export default function Layout() {
    return (
        <Fragment>
            <Navbar />
            <div className='pt-[88px] lg:pt-0'>
                <Outlet />
            </div>
            <Footer />
            <ToastContainer />
        </Fragment>
    )
}
