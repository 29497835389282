import React from 'react'

import { useTranslation } from 'react-i18next';

import BackgroundAboutUs from 'src/assets/image/bg-about-us.png';
import IconBiomass from 'src/assets/image/ic-biomass.svg';
import IconGHG from 'src/assets/image/ic-ghg.svg';

import ImageComponent from 'src/component/core/image'

export default function AboutUs() {

    const { t } = useTranslation();

    return (
        <div className='flex flex-col gap-12 max-w-[1280px] mx-auto px-6 py-10 relative w-full xl:flex-row xl:min-h-[400px] xl:py-24'>
            <div className='flex flex-col gap-6 justify-center relative w-full xl:gap-12 xl:max-w-[540px]'>
                <div className='flex flex-col gap-3 relative'>
                    <h1 className='font-black text-primary'>{t("About")} <span className='font-normal italic text-primary'>{t("Us")}</span></h1>
                    <h6>
                        <b>{t("NatureTrust")}</b> {t("is an independent third-party certification, validation and verification body that offers services for both national and international standards")}
                        <br /><br />
                        {t("Our service encompasses various industries including carbon, forestry, environmental services, renewable energy, agriculture, and management systems")}
                    </h6>
                </div>
            </div>
            <div className='relative w-full xl:h-[400px]'>
                <ImageComponent src={BackgroundAboutUs} alt='Background' className='absolute hidden left-0 -top-40 xl:block z-[1]' height={450} width={320} />
                <div className='bg-primary p-10 w-full z-[1] xl:absolute xl:bottom-0 xl:max-w-[420px] md:right-0'>
                    <div className='relative'>
                        <h3 className='font-bold text-white'>{t("Our Services")}</h3>
                        <div className='bg-white mt-3 w-[60px]'></div>
                    </div>
                    <div className='flex flex-col gap-6 mt-10'>
                        <div className='flex gap-6 items-center relative'>
                            <ImageComponent src={IconGHG} alt='GHG' height={48} width={48} />
                            <h6 className='font-bold text-white'>{t("GHG Validation and Verification")}</h6>
                        </div>
                        <div className='flex gap-6 items-center relative'>
                            <ImageComponent src={IconBiomass} alt='Biomass' height={48} width={48} />
                            <h6 className='font-bold text-white'>{t("Carbon and Biomass Certification")}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
